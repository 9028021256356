




































import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { namespace } from 'vuex-class';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';
import ProfileRoleRelation from '@/utils/enums/ProfileRoleRelation';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';

const communityUserProfileRoleStore = namespace('CommunityUserProfileRoleStore');
const toastStore = namespace('ToastStore');

@Component({
  components: { FilterItemComponent },
})
export default class MyProfileRolesWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'MyProfileRolesWidgetStore';

  @Prop({ required: false, default: null })
  private readonly type!: string;

  @Prop({ required: false, default: () => [] })
  private readonly profileRoles!: CommunityUserProfileRole[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @communityUserProfileRoleStore.Action
  private deleteUserProfileRole!: (uid: string) => Promise<CommunityUserProfileRole | undefined>;

  @communityUserProfileRoleStore.Action
  private createUserProfileRole!: (
    communityUserProfileRole: CommunityUserProfileRole
  ) => Promise<CommunityUserProfileRole | undefined>;

  private profileRoleRelation = ProfileRoleRelation;

  private rolesSaving: string[] = [];

  private user: {
    uid: string;
    roles: CommunityUserProfileRole[];
    lookingToMeets: CommunityUserProfileRole[];
  } = {
    uid: '',
    roles: [],
    lookingToMeets: [],
  };

  private get myProfileRoles(): CommunityUserProfileRole[] {
    if (this.authUser && this.authUser.profileRoles) {
      const userProfileRole = this.authUser.profileRoles
        .filter((role) => role.relation === ProfileRoleRelation.I_AM);
      return userProfileRole.length !== 0 ? userProfileRole : [];
    }
    return [];
  }

  private get lookingToMeet(): CommunityUserProfileRole[] {
    if (this.authUser && this.authUser.profileRoles) {
      const userProfileRole = this.authUser.profileRoles
        .filter((role) => role.relation === ProfileRoleRelation.I_WANT);
      return userProfileRole.length !== 0 ? userProfileRole : [];
    }
    return [];
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('isReadyToDisplay')
  @Watch('authUser.profileRoles', { immediate: true })
  setFormUser(): void {
    if (this.isReadyToDisplay) {
      this.user.uid = this.authUser.uid;
      this.user.roles = this.myProfileRoles;
      this.user.lookingToMeets = this.lookingToMeet;
    }
  }

  private isRoleMarked(roleId: string): boolean {
    if (this.authUser && this.authUser.profileRoles.length) {
      if (this.authUser) {
        return !!(this.user.roles.find((role: CommunityUserProfileRole) => role.profileRole?.uid === roleId));
      }
    }
    return false;
  }

  private isLookingToMeetMarked(lookingToMeetId: string): boolean {
    if (this.authUser && this.authUser.profileRoles?.length) {
      if (this.lookingToMeet) {
        return !!(this.user.lookingToMeets
          .find((lookingToMeet: CommunityUserProfileRole) => lookingToMeet
            .profileRole?.uid === lookingToMeetId));
      }
    }
    return false;
  }

  private isSaving(roleId: string): boolean {
    const index = this.rolesSaving.indexOf(roleId);
    return index > -1;
  }

  private onMyRoleItemClick(roleId: string): void {
    const index = this.rolesSaving.indexOf(roleId);
    if (index === -1) {
      this.rolesSaving.push(roleId);
      const relation = this.type;
      if (this.myProfileRoles) {
        let userProfileRole = (relation === this.profileRoleRelation.I_AM
          ? this.user.roles
          : this.user.lookingToMeets)
          .find((role: CommunityUserProfileRole) => role.profileRole
            && role.profileRole.uid === roleId);
        if (userProfileRole) {
          if (this.authUser.profileRoles
            && (this.user.roles.length > 0 || this.user.lookingToMeets.length > 0)) {
            const findIndex = this.authUser.profileRoles
              .findIndex((el: CommunityUserProfileRole): boolean => el.profileRole?.uid === roleId
                && (relation === ProfileRoleRelation.I_WANT
                  ? el.relation === ProfileRoleRelation.I_WANT
                  : el.relation === ProfileRoleRelation.I_AM));
            this.authUser.profileRoles.splice(findIndex, 1);
            this.deleteUserProfileRole(userProfileRole.uid)
              .then(() => {
                this.rolesSaving = this.rolesSaving.filter((roleUid) => roleId !== roleUid);
              });
            this.$logger.log(['trackEvent', 'communityUser', 'editProfileRole',
              `${this.authUser.firstName} ${this.authUser.firstName}`, 0, {
                ...this.$logger.serialiseAuthUserDimensions(
                  this.authUser,
                  this.community.code ?? '',
                  'communityUser',
                ),
                dimension2: this.$i18n.locale,
              }]);
            this.showToast(ToastActionType.UPDATE_PERSONAL_INFORMATION);
          }
        } else {
          userProfileRole = {
            uid: '',
            relation,
            profileRole: this.profileRoles.find((e) => e.uid === roleId),
            user: this.authUser,
          };
          this.createUserProfileRole(userProfileRole)
            .then((response) => {
              this.rolesSaving = this.rolesSaving.filter((roleUid) => roleId !== roleUid);
              if (this.authUser.profileRoles && response) {
                this.showToast(ToastActionType.UPDATE_PERSONAL_INFORMATION);
                this.authUser.profileRoles.push(response);
              }
            });
        }
      }
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
